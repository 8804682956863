<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Liste des vidéos formations</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :is-loading.sync="isLoading"
                :columns="columns"
                :rows="remoteData.rows"
                :totalRows="remoteData.totalRecords"
                :pagination-options="pagination"
                :sort-options="{
                    enabled: false,
                    initialSortBy: {
                        field: 'id',
                        type: 'asc',
                    },
                }"
                styleClass="vgt-table striped bordered condensed"
            >
                <template v-slot:table-row="props" class="vgt-center-align">
                    <span v-if="props.column.field === 'actions'">
                        <vk-button
                            type="primary"
                            class="uk-border-rounded uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                            title="Play"
                            @click="viewVideo(props.row)"
                        >
                            <vk-icon icon="play" />
                        </vk-button>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>

            <VideoFormationModal :currentView="currentView" @close="viewVideo(null)" />
        </div>
    </div>
</template>

<script>
import store from '@/store'
import backendApi from '@/backend/api'
import VideoFormationModal from '../components/VideoFormationModal.vue'

export default {
    name: 'VideoFormation',
    components: {
        VideoFormationModal,
    },
    data: () => ({
        isLoading: false,
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'top',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
        },
        serverParams: {
            columnFilters: {},
            sort: [
                {
                    field: 'id',
                    type: 'asc',
                },
            ],
            page: 1,
            perPage: 10,
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
        currentView: null,
    }),
    computed: {
        columns: function () {
            return [
                {
                    label: '#',
                    field: 'id',
                    tdClass: 'text-break',
                    sortable: true,
                },
                {
                    label: 'Rubrique',
                    field: 'heading',
                    tdClass: 'text-break',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filtre par rubrique',
                    },
                    sortable: true,
                },
                {
                    label: 'Titre',
                    field: 'name',
                    tdClass: 'text-break',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filtre par titre',
                    },
                    sortable: true,
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    width: '1%',
                    sortable: false,
                },
            ]
        },
    },
    methods: {
        viewVideo(video) {
            this.currentView = video
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage, page: 1 })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params, page: 1 })
            this.loadItems()
        },
        onColumnFilter(params) {
            this.updateParams({ ...params, page: 1 })
            this.loadItems()
        },
        loadItems() {
            backendApi.getVideoFormation(store.state.login.user.token, this.serverParams).then((data) => {
                this.remoteData.totalRecords = data.totalRecords
                this.remoteData.rows = data.rows
                this.updateParams({
                    page: data.currentPage,
                    perPage: data.maxResult,
                })
            })
        },
    },
    mounted() {
        this.loadItems()
    },
}
</script>

<style scoped></style>
